import React from "react";

const BenefitsGrid = () => {
  return (
    <section className="bg-accent-100 relative py-8 ">
      <div className="container flex flex-wrap py-3 mx-auto px-4 md:px-6">
        <div className="w-full flex items-center">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-3 md:gap-6 text-center w-full">


            <div className="flex items-center md:items-start py-1 md:py-0">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="#5faa3e"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                className="w-10 h-10 md:w-12 md:h-12 flex-shrink-0"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M5 21v-16a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v16l-3 -2l-2 2l-2 -2l-2 2l-2 -2l-3 2" />
                <path d="M14 8h-2.5a1.5 1.5 0 0 0 0 3h1a1.5 1.5 0 0 1 0 3h-2.5m2 0v1.5m0 -9v1.5" />
              </svg>

              <div className="text-left pl-3">
                <h3 className="font-display font-semibold text-gray-800 leading-5">
                Real Prices. Real Fast.
                </h3>
                <p className="text-gray-600 text-sm">
                  Straight forward pricing, and fast free quotes.
                </p>
              </div>
            </div>
            <div className="flex items-center md:items-start py-1 md:py-0">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-10 h-10 md:w-12 md:h-12 flex-shrink-0"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="#5faa3e"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <line x1="9" y1="15" x2="15" y2="9" />
                <circle cx="9.5" cy="9.5" r=".5" fill="currentColor" />
                <circle cx="14.5" cy="14.5" r=".5" fill="currentColor" />
                <path d="M5 7.2a2.2 2.2 0 0 1 2.2 -2.2h1a2.2 2.2 0 0 0 1.55 -.64l.7 -.7a2.2 2.2 0 0 1 3.12 0l.7 .7a2.2 2.2 0 0 0 1.55 .64h1a2.2 2.2 0 0 1 2.2 2.2v1a2.2 2.2 0 0 0 .64 1.55l.7 .7a2.2 2.2 0 0 1 0 3.12l-.7 .7a2.2 2.2 0 0 0 -.64 1.55v1a2.2 2.2 0 0 1 -2.2 2.2h-1a2.2 2.2 0 0 0 -1.55 .64l-.7 .7a2.2 2.2 0 0 1 -3.12 0l-.7 -.7a2.2 2.2 0 0 0 -1.55 -.64h-1a2.2 2.2 0 0 1 -2.2 -2.2v-1a2.2 2.2 0 0 0 -.64 -1.55l-.7 -.7a2.2 2.2 0 0 1 0 -3.12l.7 -.7a2.2 2.2 0 0 0 .64 -1.55v-1" />
              </svg>

              <div className="text-left pl-3">
                <h3 className="font-display font-semibold text-gray-800 leading-5">
                Big Savings
                </h3>
                <p className="text-gray-600 text-sm">
                We offer generous savings if multiple services are performed.
                </p>
              </div>
            </div>

            <div className="flex items-center md:items-start py-1 md:py-0">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="#5faa3e"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                className="w-10 h-10 md:w-12 md:h-12 flex-shrink-0"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2" />
                <rect x="9" y="3" width="6" height="4" rx="2" />
                <path d="M9 14l2 2l4 -4" />
              </svg>
              <div className="text-left pl-3">
                <h3 className="font-display font-semibold text-gray-800 leading-5">
                  High Quality
                </h3>
                <p className="text-gray-600 text-sm">
                  Detail oriented tests and efficient Title 24 designs.
                </p>
              </div>
            </div>
            <div className="flex items-center md:items-start py-1 md:py-0">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-10 h-10 md:w-12 md:h-12 flex-shrink-0"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="#5faa3e"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <circle cx="15" cy="15" r="3" />
                <path d="M13 17.5v4.5l2 -1.5l2 1.5v-4.5" />
                <path d="M10 19h-5a2 2 0 0 1 -2 -2v-10c0 -1.1 .9 -2 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -1 1.73" />
                <line x1="6" y1="9" x2="18" y2="9" />
                <line x1="6" y1="12" x2="9" y2="12" />
                <line x1="6" y1="15" x2="8" y2="15" />
              </svg>
              <div className="text-left pl-3">
                <h3 className="font-display font-semibold text-gray-800 leading-5">
                  Licensed and Insured
                </h3>
                <p className="text-gray-600 text-sm">
                  Licensed, certified, and insured.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BenefitsGrid;
